import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { useRouter } from 'next/router';
import { AppRoutes } from 'src/lib/routes';
import Link from 'next/link';
import ImageWithFallback from 'src/components/ImageWithFallback';
import AboutImg from 'public/images/illu_6.png';
import Illu2 from 'public/images/gift_card/illu_2.png';
import Illu3 from 'public/images/gift_card/illu_3.png';
import { FiChevronDown } from 'react-icons/fi';
import classNames from 'classnames';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ClientOnlyPortal from 'src/components/ClientOnlyPortal';
import useFilter from 'src/hooks/useFilter';
import useInitialData from 'src/hooks/useInitialData';
import { sortBy } from 'lodash-es';

export const GiftItems: React.FC = () => {
  const initialData = useInitialData();
  const giftGuideNavs = initialData.settings.giftGuideNavs;

  return (
    <>
      <div className="tw-mb-8">
        <div className="tw-font-semibold tw-font-styleSans tw-text-3xl tw-text-brand-navy tw-mb-3 tw-leading-none">
          Gifts
        </div>
        <Link legacyBehavior prefetch={false} href={AppRoutes.giftsSendRoute().toPath()}>
          <a className="tw-block tw-mb-2 tw-cursor-pointer tw-text-brand-navy hover:tw-font-semibold">
            The Best Gift Card Ever
          </a>
        </Link>
        <Link legacyBehavior prefetch={false} href={AppRoutes.giftsRoute().toPath()}>
          <a className="tw-block tw-mb-2 tw-cursor-pointer tw-text-brand-navy hover:tw-font-semibold">
            All Gift Experiences
          </a>
        </Link>
      </div>
      {giftGuideNavs && giftGuideNavs.length > 0 ? (
        <div>
          <div className="tw-font-semibold tw-font-styleSans tw-text-3xl tw-text-brand-navy tw-mb-3 tw-leading-none">
            Gift Guides
          </div>
          {giftGuideNavs.map((o, index) => (
            <Link legacyBehavior prefetch={false} href={AppRoutes.shortMagazineRoute({ id: o.blogId }).toPath()} key={index}>
              <a className="tw-block tw-mb-2 tw-cursor-pointer tw-text-brand-navy hover:tw-font-semibold">
                {o.title}
              </a>
            </Link>
          ))}
        </div>
      ) : null}
    </>
  );
};

type Props = {};

const GiftDropdown: React.FC<Props> = () => {
  const { isScreenMd } = useBreakpoints();
  const [show, setShow] = useState(false);
  const buttonRef = useRef(null);
  const eleRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const initialData = useInitialData();
  const singapore = initialData.countries.find((o) => o.slug === 'singapore');
  const giftGuideNavs = initialData.settings.giftGuideNavs;
  const giftCollections = initialData.giftCollections;

  const { setShouldCloseFilterItem } = useFilter();

  useOnClickOutside(eleRef, (eventTarget) => {
    if (overlayRef.current?.contains(eventTarget)) {
      return;
    }
    setShow(false);
  });

  useEffect(() => {
    if (router.isReady) {
      router.events.on('routeChangeComplete', () => {
        setShow(false);
      });
    }
  }, [router]);

  return (
    <>
      <div ref={eleRef}>
        <div
          className="tw-cursor-pointer"
          ref={buttonRef}
          onClick={() => setShow(true)}
          onMouseEnter={() => {
            setShow(true);
            setShouldCloseFilterItem((prev) => prev + 1);
          }}
          onMouseLeave={() => setShow(false)}
        >
          <span className="tw-text-brand-navy">Gifts</span>
          <FiChevronDown size={16} className="tw-text-brand-navy" />
        </div>

        <Overlay
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -3],
                },
              },
            ],
          }}
          placement="bottom"
          target={buttonRef.current}
          show={show}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              ref={(e) => {
                props.ref(e);
                overlayRef.current = e;
              }}
              style={{
                ...props.style,
                width: 'calc(100% - 5px)',
              }}
              className="tw-pt-7 tw-z-1200"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <div className="tw-bg-brand-yellow tw-py-8 tw-px-48 tw-font-styleSans">
                <div className="tw-flex tw-justify-center">
                  <div className="tw-pl-16 tw-text-center">
                    <Link legacyBehavior prefetch={false} href={AppRoutes.giftsSendRoute().toPath()}>
                      <div className="tw-cursor-pointer">
                        <div className="tw-text-xl tw-font-styleSans tw-font-semibold">
                          The Best Gift Card Ever
                        </div>
                        <ImageWithFallback
                          src={Illu3}
                          alt=""
                          className="tw-w-auto tw-h-32 tw-inline-block tw-static"
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="tw-px-16 tw-text-center">
                    <Link legacyBehavior prefetch={false} href={AppRoutes.giftsRoute().toPath()}>
                      <div className="tw-cursor-pointer">
                        <div className="tw-text-xl tw-font-styleSans tw-font-semibold">
                          Or, Gift an Experience
                        </div>
                        <ImageWithFallback
                          src={Illu2}
                          alt=""
                          className="tw-w-auto tw-h-32 tw-inline-block tw-static"
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="tw-border-l tw-border-solid tw-h tw-border-grey-lighter tw-mx-14"></div>
                  <div className="tw-pr-12">
                    <div className="tw-font-styleSans tw-font-semibold tw-mb-2">All Gifts</div>
                    <Link legacyBehavior prefetch={false} href={AppRoutes.giftsSendRoute().toPath()}>
                      <a className="tw-block tw-mb-2 tw-font-styleSans tw-cursor-pointer tw-text-brand-navy hover:tw-underline tw-break-words">
                        The Best Gift Card Ever
                      </a>
                    </Link>
                    {giftCollections && giftCollections.length > 0
                      ? sortBy(giftCollections, (o) =>
                          o.giftCountryId === singapore?.id ? 0 : 1
                        ).map((o, index) => (
                          <Link legacyBehavior prefetch={false} href={AppRoutes.collectionRoute({ id: o.id }).toPath()} key={index}>
                            <a className="tw-block tw-mb-2 tw-font-styleSans tw-cursor-pointer tw-text-brand-navy hover:tw-underline tw-break-words">
                              {o.name}
                            </a>
                          </Link>
                        ))
                      : null}
                  </div>
                  {giftGuideNavs && giftGuideNavs.length > 0 ? (
                    <div>
                      <div className="tw-font-styleSans tw-font-semibold tw-mb-2">Gift Guides</div>
                      {giftGuideNavs.map((o, index) => (
                        <Link legacyBehavior prefetch={false}                           href={AppRoutes.shortMagazineRoute({ id: o.blogId }).toPath()}
                          key={index}
                        >
                          <a className="tw-block tw-mb-2 tw-font-styleSans tw-cursor-pointer tw-text-brand-navy hover:tw-underline tw-break-words">
                            {o.title}
                          </a>
                        </Link>
                      ))}
                    </div>
                  ) : null}
                </div>

                <div className="tw-mt-8 tw-hidden">
                  <ImageWithFallback
                    src={AboutImg}
                    alt=""
                    className="tw-static tw-block tw-h-48 tw-w-auto tw-mx-auto"
                  />
                </div>
              </div>
            </div>
          )}
        </Overlay>
      </div>

      <ClientOnlyPortal selector="#bottom-body">
        {isScreenMd ? (
          <div
            className={classNames(
              'tw-bg-black tw-bottom-0 tw-left-0 tw-opacity-30 tw-fixed tw-w-screen tw-z-101',
              { 'tw-hidden': !show }
            )}
            style={{ height: 'calc(100vh - 72px)' }}
          ></div>
        ) : null}
      </ClientOnlyPortal>
    </>
  );
};

export default GiftDropdown;
