import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import Script from 'next/script';
import { isProduction } from 'src/helpers';
import Link from 'next/link';
import { AppRoutes } from 'src/lib/routes';
import ApiClient from 'src/lib/api_client';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import humps, { Decamelized } from 'humps'

const DEFAULT_CONSENT_MODE = {
  performanceCookie: true,
  functionalCookie: true,
  targetingCookie: true,
  necessaryCookie: true,
};

const CookieConsentBanner: React.FC = () => {
  const countCookieConsent = useEnhanceApiClient(ApiClient.countCookieConsent)
  const [showModal, setShowModal] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [consentMode, setConsentMode] = useState(DEFAULT_CONSENT_MODE);
  const [confirmed, setConfirmed] = useState(false);
  const [autoConfirmed, setAutoConfirmed] = useState(false);

  useEffect(() => {
    if (!window.localStorage.getItem('consentMode')) {
      setShowModal(true);
    } else {
      const values = JSON.parse(window.localStorage.getItem('consentMode') || '{}');
      setConsentMode({
        ...DEFAULT_CONSENT_MODE,
        ...values,
      });
      setAutoConfirmed(true);
    }
  }, []);

  useEffect(() => {
    if ((confirmed || autoConfirmed) && isProduction()) {
      window.fbq('consent', 'grant');

      window.gtag('consent', 'update', {
        ad_user_data: consentMode.targetingCookie ? 'granted' : 'denied',
        ad_personalization: consentMode.targetingCookie ? 'granted' : 'denied',
        ad_storage: consentMode.targetingCookie ? 'granted' : 'denied',
        analytics_storage: consentMode.performanceCookie ? 'granted' : 'denied',
        personalization_storage: consentMode.functionalCookie ? 'granted' : 'denied',
        functionality_storage: consentMode.necessaryCookie ? 'granted' : 'denied',
        security_storage: consentMode.necessaryCookie ? 'granted' : 'denied',
      });

      const params = humps.decamelizeKeys(consentMode) as Decamelized<typeof consentMode>
      if (confirmed) {
        countCookieConsent(params)
      }
    }
  }, [autoConfirmed, confirmed, consentMode]);

  return (
    <>
      {showModal ? (
        <div className="tw-fixed tw-z-101 tw-bottom-0 tw-left-0 tw-p-6 md:tw-p-10 tw-bg-white tw-w-full" style={{ boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)' }}>
          <div className="">
            <div className="tw-mb-4">
              We use cookies to give you the best possible experience with seeksophie.com. Some are
              essential for this site to function; others help us understand how you use the site,
              so we can improve it. We may also use cookies for targeting purposes. Click “Accept
              all cookies” to proceed as specified, or click “Manage my preferences” to choose the
              types of cookies you will accept.{' '}
              <Link legacyBehavior prefetch={false} href={AppRoutes.privacyRoute().toUrl()}>
                <a className="tw-text-brand-navy tw-font-semibold tw-underline">Privacy Policy</a>
              </Link>
            </div>

            <div className="tw-flex tw-items-center">
              <button
                type="button"
                className="btn btn-primary tw-block tw-w-1/2 md:tw-w-auto tw-mr-2 md:tw-mr-4"
                onClick={() => {
                  const values = {
                    performanceCookie: true,
                    targetingCookie: true,
                    functionalCookie: true,
                    necessaryCookie: true,
                  };
                  setConsentMode(values);
                  setConfirmed(true);
                  window.localStorage.setItem('consentMode', JSON.stringify(values));
                  setShowModal(false);
                }}
              >
                Accept all cookies
              </button>
              <button
                type="button"
                className="btn btn-white tw-border tw-border-black tw-border-solid tw-block tw-w-1/2 md:tw-w-auto tw-ml-2 md:tw-ml-0"
                onClick={() => {
                  setShowPreferences(true);
                }}
              >
                Manage my preferences
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <Modal
        show={showPreferences}
        className="modal-styled"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="tw-w-full tw-flex">
            <div
              className="tw-ml-auto tw-cursor-pointer"
              onClick={() => {
                setShowPreferences(false);
              }}
            >
              <FiX size={24} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="tw-font-styleSans tw-font-semibold tw-text-xl md:tw-text-3xl tw-leading-none md:tw-leading-none tw-mb-2">
            Privacy Preference Center
          </div>
          <div className="tw-mb-6">
            Seek Sophie and our trusted partners use cookies and similar technologies to access and
            use your data for the purposes listed below. Please provide your consent for cookie
            usage on this website. Enable one or more of the cookie types listed below, and then
            save your preferences.
          </div>
          <div className="tw-font-styleSans tw-font-semibold tw-text-xl md:tw-text-3xl tw-leading-none md:tw-leading-none tw-mb-4">
            Manage Consent Preferences
          </div>

          <div className="tw-flex tw-items-center tw-mb-2">
            <div className="tw-font-styleSans tw-font-semibold tw-text-lg md:tw-text-xl tw-leading-none md:tw-leading-none">
              Performance Cookies
            </div>
            <div className="form-check tw-ml-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="performance-cookie"
                checked={consentMode.performanceCookie}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setConsentMode((prev) => ({
                    ...prev,
                    performanceCookie: e.target.checked,
                  }));
                }}
              />
              <label className="form-check-label" htmlFor="performance-cookie"></label>
            </div>
          </div>
          <div className="tw-mb-4">
            These cookies allow us to count visits and traffic sources so we can measure and improve
            the performance of our site and app. They help us to know which pages are the most and
            least popular and see how visitors move around the site and app. All information these
            cookies collect is aggregated and therefore anonymous. If you do not allow these cookies
            we will not know when you have visited our site or app, and will not be able to monitor
            its performance.
          </div>

          <div className="tw-flex tw-items-center tw-mb-2">
            <div className="tw-font-styleSans tw-font-semibold tw-text-lg md:tw-text-xl tw-leading-none md:tw-leading-none">
              Functional Cookies
            </div>
            <div className="form-check tw-ml-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="functional-cookie"
                checked={consentMode.functionalCookie}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setConsentMode((prev) => ({
                    ...prev,
                    functionalCookie: e.target.checked,
                  }));
                }}
              />
              <label className="form-check-label" htmlFor="functional-cookie"></label>
            </div>
          </div>
          <div className="tw-mb-4">
            These cookies enable the website and app to provide enhanced functionality and
            personalization. They may be set by us or by third party providers whose services we
            have added to our pages. If you do not allow these cookies then some or all of these
            services may not function properly.
          </div>

          <div className="tw-flex tw-items-center tw-mb-2">
            <div className="tw-font-styleSans tw-font-semibold tw-text-lg md:tw-text-xl tw-leading-none md:tw-leading-none">
              Targeting Cookies
            </div>
            <div className="form-check tw-ml-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="targeting-cookie"
                checked={consentMode.targetingCookie}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setConsentMode((prev) => ({
                    ...prev,
                    targetingCookie: e.target.checked,
                  }));
                }}
              />
              <label className="form-check-label" htmlFor="targeting-cookie"></label>
            </div>
          </div>
          <div className="tw-mb-4">
            These cookies may be set through our site or app by our advertising partners. They may
            be used by those companies to build a profile of your interests and show you relevant
            adverts on other sites. They do not store directly personal information, but are based
            on uniquely identifying your browser and internet device. If you do not allow these
            cookies, you will experience less targeted advertising.
          </div>

          <div className="tw-flex tw-items-center tw-mb-2">
            <div className="tw-font-styleSans tw-font-semibold tw-text-lg md:tw-text-xl tw-leading-none md:tw-leading-none">
              Strictly Necessary Cookies
            </div>
            <div className="form-check tw-ml-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="necessary-cookie"
                checked={consentMode.necessaryCookie}
                readOnly
              />
              <label className="form-check-label" htmlFor="necessary-cookie">
                Always Active
              </label>
            </div>
          </div>
          <div className="tw-mb-4">
            These cookies are necessary for the website and app to function and cannot be switched
            off in our systems. They are usually only set in response to actions made by you which
            amount to a request for services, such as setting your privacy preferences, logging in
            or filling in forms. You can set your browser to block or alert you about these cookies,
            but some parts of the site and app will not then work. These cookies do not store any
            personally identifiable information.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="tw-flex tw-justify-center tw-items-center">
            <button
              type="button"
              className="btn btn-secondary tw-mr-2 tw-w-1/2 md:tw-w-auto"
              onClick={() => {
                window.localStorage.setItem('consentMode', JSON.stringify(consentMode));
                setConfirmed(true);
                setShowPreferences(false);
                setShowModal(false)
              }}
            >
              Save my preferences
            </button>
            <button
              type="button"
              className="btn btn-primary tw-ml-2 tw-w-1/2 md:tw-w-auto"
              onClick={() => {
                const values = {
                  performanceCookie: true,
                  targetingCookie: true,
                  functionalCookie: true,
                  necessaryCookie: true,
                };
                setConsentMode(values);
                setConfirmed(true);
                window.localStorage.setItem('consentMode', JSON.stringify(values));
                setShowPreferences(false);
                setShowModal(false)
              }}
            >
              Accept all cookies
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CookieConsentBanner;
