var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0caab0e228ccabc824f2e6f33b0ce56cc46cbe45"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    ignoreErrors: [
      /load failed/i,
      'Failed to fetch',
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
      'Illegal invocation',
      'The operation was aborted',
      'The play() request was interrupted by a call to pause()'
    ]
  });
}
